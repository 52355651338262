<template>
  <b-row>
    <b-col
      sm="12"
      md="4"
    >
      <b-card title="Makine Kartı">
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Proje Numarası
            </div>
            <div>{{ machine.project }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Fabrika / Seri No
            </div>
            <div>{{ machine.serial }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Kurulum Yılı
            </div>
            <div>{{ machine.syear }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Tipi
            </div>
            <div>{{ machine.machine_type }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Modeli
            </div>
            <div>{{ machine.machine_model }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Temizleme Teknolojisi
            </div>
            <div>{{ machine.machine_cleaning }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Durumu
            </div>
            <div>{{ machine.machine_status }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Servis Talebi Oluştur">
        <b-form-group
          label="Servis Talebi"
          label-for="notes"
        >
          <b-form-textarea
            id="notes"
            v-model="formData.notes"
            placeholder="Servis Talebi"
          />
        </b-form-group>
        <b-button
          variant="danger"
          :disabled="!formData.notes"
          @click="submitForm"
        >
          Gönder
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormTextarea, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        notes: null,
      },
    }
  },
  computed: {
    machine() {
      return this.$store.getters['machines/getMachine']
    },
    saveStatus() {
      return this.$store.getters['serviceRequests/getService_requestSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          project: null,
          serial: null,
          syear: null,
          status: null,
          notes: null,
          id_machine_types: null,
          id_machine_models: null,
          id_machine_cleaning: null,
          id_machine_statuses: null,
          id_customers: null,
          id_customer_users: null,
          id_customer_address: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getMachine()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getMachine() {
      this.$store.dispatch('machines/machineView', this.$route.params.id)
    },
    submitForm() {
      this.formData.id_machines = this.machine.id
      this.submitStatus = true
      this.$store.dispatch('serviceRequests/service_requestSave', this.formData)
    },
  },
}
</script>
